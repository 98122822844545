import { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import Circular from "@/components/Circular";
import PostItemList from "@/features/post/components/PostItemList";
import ProductContactLinks from "@/features/product/components/ProductContactLinks";
import {
  PostResponse,
  ProductDetailResponse,
  ProductKind,
  ProductVariationWithCatalogPagesResponse,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import usePagination from "@/hooks/usePagination";
import Link from "next/link";
import InfiniteScroll from "react-infinite-scroll-component";
import client from "@/utils/api/client";
import usePageView from "@/hooks/usePageView";
import useProductDetail from "@/features/product/hooks/useProductDetail";
import PostItem from "@/features/post/components/PostItem";
import ModalApplyableProductVariations from "@/features/sample_item/components/ModalApplyableProductVariations";
import useDisclosure from "@/hooks/useDisclosure";
import useViewEvent from "@/hooks/useViewEvent";
import useConvertProductDetailToAnalyticsParam from "@/hooks/useConvertProductDetailToAnalyticsParam";
import { NextSeo } from "next-seo";
import publicPath from "@/utils/publicPath";
import ProductTable from "@/features/product/components/ProductTable";
import BorderList from "@/components/BorderList";
import useArrayMutation from "@/hooks/useArrayMutation";
import useSearchQueryParameter from "@/hooks/useSearchQueryParameter";
import { ProductSearchQueryParameter } from "@/types/ProductSearchQueryParameter";
import { useRouter } from "next/router";
import useProductVariationGroup, {
  ProductVariationGroup,
} from "@/features/product_variation/hooks/useProductVariationGroup";
import useDisplayPrice from "@/hooks/useDisplayPrice";
import useImageLoader from "@/hooks/useImageLoader";
import MakerContactModal from "@/features/maker_contact/components/MakerContactModal";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";
import { RedirectReason } from "@/domain/values/RedirectReason";
import ExpiredProductAlert from "@/features/product/components/ExpiredProductAlert";
import { motion } from "framer-motion";

import dynamic from "next/dynamic";
const DynamicModalProductVariationSimilarities = dynamic(
  () =>
    import(
      "@/features/product_variation/components/ModalProductVariationSimilarities"
    ),
);
import Image from "next/image";
import useAnalytics from "@/hooks/useAnalytics";
import clsx from "clsx";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { getReasonText } from "@/utils/getReasonText";

interface Props {
  product: ProductDetailResponse;
  scrollableTargetId?: string;
  mutate: (
    updatedProduct?: ProductDetailResponse,
    shouldValidate?: boolean,
  ) => void;
}

const ProductShowTemplate: FC<Props> = ({
  product,
  scrollableTargetId,
  mutate,
}) => {
  usePageView(product.maker_id);
  const { query } = useRouter();
  const { convert } = useConvertProductDetailToAnalyticsParam();
  const { event } = useAnalytics();

  const {
    isOpen: isOpenApplyableSampleModal,
    closeHandler: closeApplyableSampleModal,
    openHandler: openApplyableSampleModal,
  } = useDisclosure(false);

  const {
    isOpen: isOpenContactModal,
    closeHandler: closeContactModal,
    openHandler: openContactModal,
  } = useDisclosure(false);

  const modalProductVariationSimilarities = useDisclosure(false);

  const { queryController } =
    useSearchQueryParameter<ProductSearchQueryParameter>(
      product.kind === ProductKind.INTERIOR
        ? "/interiors/product_variations/search"
        : "/building_materials/product_variations/search",
    );

  const { displayName, hasContactMethods, hasSample, minPrice, maxPrice } =
    useProductDetail(product);
  const { currentGroup, groups, setCurrentGroup } =
    useProductVariationGroup(product);

  const {
    data: posts,
    next,
    hasNext,
    dataLength,
    updateItem,
  } = usePagination<PostResponse>(
    `/products/${product.id}/posts`,
    20,
    async ({ limit, cursor }) => {
      const { data } = await client.productsIdPostsGet({
        limit,
        cursor: cursor as number,
        id: product.id,
      });

      return data;
    },
  );

  const { update } = useArrayMutation<
    | ProductVariationWithProductResponse
    | ProductVariationWithCatalogPagesResponse
  >();
  const mutateVariation = useCallback(
    (updatedProduct) =>
      mutate(
        {
          ...product,
          variations: update(product.variations, updatedProduct),
        },
        false,
      ),
    [mutate, update, product],
  );

  const { openIfNotDesigner } = useModalLimitFunction();
  const { executeAction: clickContactBtnHandler, SignInModalComponent } =
    useSignInRequiredAction({
      action() {
        openIfNotDesigner({ onDesigner: openContactModal });
      },
      message: getReasonText(RedirectReason.ContactMaker),
    });

  useViewEvent(
    "view_item",
    {
      items: [convert(product)],
    },
    product.maker_id,
    !hasSample,
  );

  // v_idからどのグループをみているかを判断する
  useEffect(() => {
    if (!query.v_id) {
      setCurrentGroup(groups[0]);

      return;
    }

    const targetGroup = groups.find((group) =>
      group.variations.some((variation) => variation.id === Number(query.v_id)),
    );
    setCurrentGroup(targetGroup || groups[0]);
  }, [query, groups, setCurrentGroup]);

  const price = useDisplayPrice(
    { min_price: minPrice, max_price: maxPrice },
    {
      prefixStyle: { fontSize: 16 },
      suffixStyle: { fontSize: 14 },
    },
  );

  // メイン画像を読み込みの進捗を確認する
  const { isLoading, isComplete } = useImageLoader(
    currentGroup ? currentGroup.image.urls.original : "",
  );

  // サンプル請求ボタンと問い合わせボタンの表示判定
  const displayActionBtns = useMemo<boolean>(() => {
    if (!product.maker.is_contracted) return false;

    return hasSample || product.maker.can_service_contacted;
  }, [product, hasSample]);

  // 類似品検索モーダルを開くボタンのクリックハンドラ
  const clickSearchSimilarsBtnHandler = useCallback(() => {
    event("click_button", {
      content_type: "search_similars",
      item_id: `PROD_${product.id}`,
    });

    modalProductVariationSimilarities.openHandler();
  }, [modalProductVariationSimilarities, product, event]);

  return (
    <>
      <NextSeo
        title={displayName}
        description={`${product.maker.name}の${displayName}のページです。`}
        openGraph={{
          images: product.variations.map((v) => {
            return { url: v.upload_image.urls.original };
          }),
          type: "article",
        }}
        canonical={publicPath(`/products/${product.id}`)}
      />
      <main className="laptop:max-w-[1280px] laptop:space-y-24 mx-auto laptop:py-80 laptop:px-16 pb-64 relative">
        <ExpiredProductAlert product={product} />

        <div className="laptop:space-y-80">
          <div className="grid laptop:grid-cols-[1fr_1fr] items-start">
            {/* 画像 */}
            {currentGroup && (
              <div className="laptop:sticky laptop:top-[160px] space-y-12 laptop:px-40 flex justify-center laptop:pt-64 pt-32">
                {isLoading && (
                  <img
                    className="block aspect-square object-none max-w-[360px] w-full"
                    src={"/progress.gif"}
                    alt={"読み込み中"}
                  />
                )}
                {isComplete && (
                  <img
                    className="block aspect-square bg-white object-contain max-w-[360px] w-full"
                    src={currentGroup.image.urls.original}
                    alt={product.full_name}
                  />
                )}
              </div>
            )}

            {/* データ */}
            <div className="laptop:col-start-2 laptop:row-start-1 laptop:row-end-3 small:px-16 space-y-32 laptop:border-l border-primary pl-40">
              <div className="small:pt-24">
                <BorderList borderStyle="solid">
                  <li>
                    <div className="space-y-24 pb-24">
                      <div className="space-y-24">
                        <ul className="text-xs space-y-4">
                          <li>
                            メーカー：
                            {product.maker.is_contracted ? (
                              <Link
                                href={`/makers/${product.maker.id}`}
                                prefetch={false}
                                className="text-link"
                              >
                                {product.maker.name}
                              </Link>
                            ) : (
                              <>{product.maker.name}</>
                            )}
                          </li>
                          <li>
                            ブランド：
                            {product.maker.is_contracted ? (
                              <Link
                                href={`/brands/${product.brand.id}`}
                                prefetch={false}
                                className="text-link"
                              >
                                {product.brand.name}
                              </Link>
                            ) : (
                              <>{product.brand.name}</>
                            )}
                          </li>
                        </ul>

                        <div className="space-y-24">
                          <div className="space-y-4">
                            <ul className="flex flex-wrap text-xs text-secondary">
                              {product.categories.map((category, index) => (
                                <li key={category.id}>
                                  {index !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
                                  <Link
                                    href={queryController.toPath({
                                      category_id: category.id.toString(),
                                    })}
                                    className="hover:underline"
                                  >
                                    {category.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>

                            <div className="flex flex-col gap-4">
                              <h1 className="font-bold text-2xl">
                                {displayName}
                              </h1>
                              {product.sample_requested_users_count > 0 && (
                                <p className="text-2xs text-secondary">
                                  <span className="font-bold">
                                    {product.sample_requested_users_count}
                                  </span>
                                  名のユーザーがこの製品のサンプルを請求しました
                                </p>
                              )}
                            </div>
                          </div>
                          {product.maker.is_contracted && (
                            <div className="text-xl">{price}</div>
                          )}
                        </div>
                      </div>

                      {displayActionBtns && (
                        <div className="flex flex-col laptop:flex-row gap-12">
                          {hasSample && (
                            <button
                              className="w-full"
                              onClick={openApplyableSampleModal}
                              type="button"
                            >
                              <span className="block bg-yellow-500 px-12 py-12 rounded-full whitespace-nowrap">
                                サンプルを請求する
                              </span>
                            </button>
                          )}

                          {product.maker.can_service_contacted && (
                            <button
                              className="w-full"
                              onClick={clickContactBtnHandler}
                              type="button"
                            >
                              <span className="block bg-gray-200 px-12 py-12 rounded-full whitespace-nowrap">
                                製品について問い合わせる
                              </span>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </li>

                  <li>
                    <div className="py-24 space-y-32">
                      <VariationContentBox
                        label={""}
                        content={
                          <div
                            className="grid gap-8"
                            style={{
                              gridTemplateColumns:
                                "repeat(auto-fill, minmax(80px, 1fr))",
                            }}
                          >
                            {currentGroup &&
                              groups.map((group) => (
                                <VariationGroupButton
                                  key={group.key}
                                  group={group}
                                  selected={group.key === currentGroup.key}
                                  onClick={() => {
                                    setCurrentGroup(group);
                                  }}
                                />
                              ))}
                          </div>
                        }
                      />

                      {product.description && (
                        <VariationContentBox
                          label={"製品説明"}
                          content={product.description}
                        />
                      )}

                      {product.maker.is_contracted && (
                        <VariationContentBox
                          label={"メーカー"}
                          content={
                            <div className="flex flex-col gap-16">
                              {product.maker.logo && (
                                <Link
                                  href={`/makers/${product.maker.id}`}
                                  className="relative"
                                  style={{ width: 200, height: 80 }} // for CLS より良い書き方があれば変更してください
                                >
                                  <Image
                                    className="object-left object-contain"
                                    src={product.maker.logo?.urls.small}
                                    alt={product.maker.name}
                                    unoptimized={true}
                                    fill
                                  />
                                </Link>
                              )}

                              <div className="flex flex-col gap-8">
                                {product.maker.copy && (
                                  <p className="text-sm font-bold">
                                    {product.maker.copy}
                                  </p>
                                )}
                                <p className="text-xs leading-relaxed line">
                                  {product.maker.description}
                                </p>
                                <Link
                                  className="text-link text-xs"
                                  href={`/makers/${product.maker.id}`}
                                >
                                  メーカーページ
                                </Link>
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>
                  </li>

                  {product.maker.is_contracted && hasContactMethods && (
                    <li className="py-24">
                      <ProductContactLinks product={product} />
                    </li>
                  )}
                </BorderList>
              </div>
            </div>
          </div>

          <div className="small:px-16 laptop:space-y-64 space-y-32">
            <hr />

            {currentGroup && (
              <ProductTable
                mutate={mutateVariation}
                group={currentGroup}
                product={product}
              />
            )}
          </div>

          {posts.length > 0 && (
            <div className="small:px-16 laptop:space-y-64 space-y-32 small:pt-48">
              <section className="space-y-12">
                <h2>{product.full_name}の利用事例</h2>
                <InfiniteScroll
                  style={{ overflow: "visible" }}
                  next={next}
                  dataLength={dataLength}
                  hasMore={hasNext}
                  loader={<Circular />}
                  scrollableTarget={scrollableTargetId}
                >
                  <PostItemList
                    posts={posts}
                    onRender={(post) => (
                      <PostItem
                        key={post.id}
                        post={post}
                        mutate={(updatedPost) => updateItem(updatedPost, false)}
                      />
                    )}
                  />
                </InfiniteScroll>
              </section>
            </div>
          )}
        </div>

        <motion.button
          initial={{ translateX: 50, opacity: 0 }}
          animate={{ translateX: 0, opacity: 1 }}
          transition={{
            delay: 1,
            type: "spring",
            stiffness: 500,
            damping: 24,
          }}
          onClick={clickSearchSimilarsBtnHandler}
          className={clsx(
            "fixed",
            "bottom-16 right-16",
            "laptop:bottom-24 laptop:right-[64px]",
            "flex gap-8 items-center shadow-default px-16 py-16 rounded-full font-bold z-10",
            "bg-gradient-to-r from-[#A803F6] to-[#FF0A71] text-white",
          )}
        >
          <i className="Icon icon-Search" />
          <span>類似製品をさがす</span>
        </motion.button>
      </main>

      <ModalApplyableProductVariations
        isOpen={isOpenApplyableSampleModal}
        closeHandler={closeApplyableSampleModal}
        product={product}
        currentGroup={currentGroup}
      />

      <MakerContactModal
        isOpen={isOpenContactModal}
        closeHandler={closeContactModal}
        maker={product.maker}
        product={product}
      />

      <DynamicModalProductVariationSimilarities
        {...modalProductVariationSimilarities}
        product={product}
        defaultProductVariationId={currentGroup?.variations[0]?.id}
      />

      {SignInModalComponent}
    </>
  );
};

export default ProductShowTemplate;

type VariationContentBoxProps = {
  label?: string;
  content: ReactNode;
};

const VariationContentBox: FC<VariationContentBoxProps> = ({
  label,
  content,
}): JSX.Element => {
  return (
    <div className="grid gap-8 tracking-wider">
      {label && <p className="font-bold text-xs text-secondary">{label}</p>}
      <div className="text-sm leading-loose whitespace-pre-line">{content}</div>
    </div>
  );
};

type VariationGroupButtonProps = {
  onClick: VoidFunction;
  selected: boolean;
  group: ProductVariationGroup;
};

const VariationGroupButton: FC<VariationGroupButtonProps> = ({
  onClick,
  selected,
  group,
}) => {
  return (
    <div className="space-y-8">
      <button
        className={[
          "block w-full relative aspect-square overflow-hidden bg-center bg-cover bg-no-repeat bg-white rounded-sm",
          "after:transition-all after:duration-300 after:block after:content-[''] after:rounded-sm after:pointer-events-none after:w-full after:h-full after:border-2 after:opacity-0",
          selected
            ? "after:opacity-100"
            : "hover:after:opacity-100 after:border-gray-200",
        ].join(" ")}
        type="button"
        onClick={onClick}
        style={{
          backgroundImage: `url(${group.image.urls.small})`,
        }}
      />
      <p className="text-2xs line-clamp-2 text-secondary text-center leading-tight">
        {group.name}
      </p>
    </div>
  );
};
