import { ProductDetailResponse } from "@/web-client/api";
import { FC, useMemo } from "react";
import useVariation from "@/features/product_variation/hooks/useVariation";
import { ProductVariationWithSample } from "@/stores/cart";
import CartBtnWrapper from "@/components/CartBtnWrapper";
import useDisplayPrice from "@/hooks/useDisplayPrice";
import useCart from "@/hooks/useCart";
import IconAddCart from "@/assets/imgs/svg/icon_add_cart.svg";
import SampleItemTSSDeliveryLabel from "@/features/product_variation/components/SampleItemTSSDeliveryLabel";
import useProductVariation from "@/features/product_variation/hooks/useProductVariation";

type Props = {
  product: ProductDetailResponse;
  productVariation: ProductVariationWithSample;
};

const ApplyableSampleItem: FC<Props> = ({
  product,
  productVariation,
}): JSX.Element => {
  const { isSampleAvailable, isIndirectAvailable } =
    useProductVariation(productVariation);
  const { hasSameSampleItem } = useCart();

  const { displaySize } = useVariation(productVariation);
  const displayPrice = useDisplayPrice(productVariation, {});

  const hasBeenInCart = useMemo<boolean>(
    () => hasSameSampleItem(productVariation),
    [productVariation, hasSameSampleItem],
  );

  return (
    <article className="flex items-start justify-between gap-24 py-24 laptop:px-16">
      <div className="flex items-start gap-16">
        <img
          className="w-[64px] h-[64px] laptop:w-[100px] laptop:h-[100px] object-contain border border-primary bg-white flex-shrink-0"
          src={productVariation.upload_image.urls.small}
          alt={productVariation.item_number}
        />
        <div className="space-y-12">
          <div>
            <p>{productVariation.full_name}</p>
            <p className="text-sm block font-bold text-secondary">
              {productVariation.item_number}
            </p>
          </div>

          <div className="text-xs">
            {displayPrice}
            {displaySize && <p>{displaySize}(mm)</p>}
          </div>
          {hasBeenInCart && (
            <p className="text-danger text-sm">
              同じサンプルがすでにカートに入っています
            </p>
          )}
        </div>
      </div>

      {isSampleAvailable && (
        <div className="flex flex-col items-end gap-8">
          {isIndirectAvailable && <SampleItemTSSDeliveryLabel />}
          <CartBtnWrapper
            productVariation={productVariation}
            product={product}
            btnView={
              <span className="block rounded-xs bg-yellow-500 font-bold p-12">
                <IconAddCart width={20} height={20} />
              </span>
            }
          />
        </div>
      )}
    </article>
  );
};
export default ApplyableSampleItem;
