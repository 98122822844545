import { FC, useMemo } from "react";
import { ProductDetailResponse } from "@/web-client/api";
import Alert from "@/components/Alert";
import IconWarning from "@/assets/imgs/svg/icon-warning.svg";
import { theme } from "tailwind.config";
import { differenceInYears } from "date-fns";

type Props = {
  product: ProductDetailResponse;
};

const ExpiredProductAlert: FC<Props> = ({ product }): JSX.Element => {
  const pastYears = useMemo<number>(
    () => differenceInYears(new Date(), new Date(product.updated_at)),
    [product],
  );

  const isShown = useMemo(() => {
    if (product.maker.is_contracted) return false;

    // 未契約かつ最終更新日から１年以上経過している場合は表示
    return pastYears >= 1;
  }, [product, pastYears]);

  if (!isShown) return null;

  return (
    <Alert
      severity="warning"
      frontIcon={
        <IconWarning
          width={24}
          height={24}
          fill={theme.colors.warning.normal}
        />
      }
      message={`この製品の情報は、最終更新日から${pastYears}年以上が経過しています。`}
    />
  );
};
export default ExpiredProductAlert;
